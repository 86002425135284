<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{collectionsConfig.getCollectionLabel(collection.collectionId)}}
        <small>- {{suitesConfig.getSuiteLabel(suiteId)}}</small>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          :to="`/suite-schema/${suiteId}`"
          text>
          <v-icon>settings</v-icon>
          {{translate('Edit suite schema')}}
        </v-btn>
      </v-toolbar-items>
    </template>
    <v-skeleton-loader type="table" v-if="collection.loading" />
    <v-card v-if="!collection.loading">
      <v-card-text>
        <collection-table
          :loading="collection.loading"
          :items="collection.items"
          :schema="collection.schema"
          :__channels="collection.channels"
          :relations="collection.relations"
          :reverse-relations="collection.reverseRelations"
          :suites="collection.suites"
          :sortable="true"
          :searchable="true"
          :show-select-suite-header="false"
          :show-suite-attribute-headers="true"
          :suiteId="suiteId"
          >
          <template v-slot:edit-item="{item, schema}">
            <v-btn :to="`/collection/${schema.collectionId}/${encodeURIComponent(item.key)}`" small icon color="primary">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
        </collection-table>

      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import CollectionTable from '@/components/collections/collection-table.vue'
export default {
  props: ['suiteId'],
  data: () => ({
    stateCache: null
  }),
  components: {
    LayoutPage,
    CollectionTable
  },
  computed: {
    ...mapGetters(['translate', 'api', 'collectionsConfig', 'suitesConfig'])
  },
  asyncComputed: {
    collection: {
      async get () {
        let {suiteId, collectionId} = await this.api.suites.getSuiteSchema({
          suiteId: this.suiteId
        })

        let loadAttributes = this.collectionsConfig.getTableAttributesForLoading(collectionId)
        let cd = await this.api.collections.getCollection({
          collectionId,
          query: {
            suiteId,
            attributes: loadAttributes && loadAttributes.join(',')
          }})
        return cd
      },
      default: {
        loading: true,
        items: [],
        schema: {},
        channels: {},
        relations: {},
        reverseRelations: {},
        suites: {}
      }
    }
  }

}
</script>
